
import { computed, defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { SiriActions, SiriGetters } from "@/store/vm/siri/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import NotificationAction from "@/views/notification/child/NotificationAction.vue";

export default defineComponent({
  name: "upsert-siri",
  components: {
    NotificationAction,
  },
  props: {
    siriId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["reset"],
  setup(props, { emit }) {
    const store = useStore();
    const action = ref();

    const formRef = ref<null | HTMLFormElement>(null);
    const siriUpsertModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const targetType = ref("url");
    const targetId = ref(1);
    const url = ref("");

    const defaultData = {
      id: "",
      index: "",
      type: "file",
      text: "",
    };

    const formData = ref({ ...defaultData });

    const modalUpsertId = computed(() => {
      return store.state.SiriModule.modalUpsertId;
    });

    const rules = ref({
      index: [
        {
          required: true,
          message: "Yêu cầu nhập index",
          trigger: "change",
        },
      ],
      text: [
        {
          required: true,
          message: "Yêu cầu nhập tiêu đề",
          trigger: "change",
        },
      ],
    });

    const isCreate = computed(() => {
      return !props.siriId;
    });

    const siriIdTarget = computed(() => {
      return props.siriId;
    });

    watch(siriIdTarget, (currentValue) => {
      if (currentValue) {
        const detail = store.getters[SiriGetters.GET_BY_ID](currentValue);
        formData.value = detail;

        targetType.value = detail["targetType"];
        targetId.value = detail["targetId"];

        if (detail["targetType"] === "url") {
          const extra = JSON.parse(detail["targetExtra"]);
          url.value = extra["url"] || "";
        }
      } else {
        reset();
      }
    });

    const reset = () => {
      formData.value = defaultData;
      emit("reset");
    };

    const actionCreate = (par) => {
      return store.dispatch(SiriActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(SiriActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          const actionData = action.value.get();

          const extra =
            actionData["actionType"] === "url"
              ? JSON.stringify({
                  url: actionData.url,
                })
              : "{}";

          const par = {
            ...formData.value,
            targetType: actionData.actionType,
            targetId: actionData.actionId,
            targetExtra: extra,
          };

          if (props.siriId) {
            stepResult = await actionUpdate(par);
          } else {
            stepResult = await actionCreate(par);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            hideModal(siriUpsertModalRef.value);
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            reset();
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const siriTypeLabels = [
      {
        value: "file",
        label: "File",
      },
      {
        value: "folder",
        label: "Folder",
      },
    ];

    return {
      siriTypeLabels,
      modalUpsertId,
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      siriUpsertModalRef,
      siriIdTarget,
      targetType,
      targetId,
      url,
      action,
    };
  },
});
