
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/siri/List.vue";
import Upsert from "@/views/siri/Upsert.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { SiriActions, SiriGetters } from "@/store/vm/siri/enums";

interface ComponentData {
  siriIdModal: number | boolean;
}
export default defineComponent({
  name: "siri",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
    Upsert,
  },
  data(): ComponentData {
    return {
      siriIdModal: false,
    };
  },
  methods: {
    onUpsert(siriId: number | boolean) {
      this.siriIdModal = siriId;
    },
    onReset() {
      this.siriIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Hệ thống", ["Siri béo"]);
      MenuComponent.reinitialization();
    });

    const onDel = (siriId) => {
      const detail = store.getters[SiriGetters.GET_BY_ID](siriId);

      Swal.fire({
        title: "Bạn muốn xóa Siri's suggestion này?",
        text: detail.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(siriId);
        }
      });
    };

    const actionDelete = async (siriId) => {
      return store.dispatch(SiriActions.DELETE, siriId).catch((e) => {
        console.log(e);
      });
    };

    return {
      onDel,
    };
  },
});
